<template>
  <div>
    <loader :loading="loading"/>

    <page-header :title="`Categorias de Treinamentos`">
      <template v-slot:breadcrumbs>
        <li>Categorias de Treinamentos</li>
      </template>

      <template v-slot:actions>
        <div>
          <button class="btn btn-success" @click="clearFields" data-bs-toggle="modal" data-bs-target="#category-modal">Nova Categoria</button>
        </div>
      </template>
    </page-header>

    <filters @search="handleSearch(filters, 1)" @clear="clearFilters">
      <form @keyup.enter="handleSearch(filters)">
        <div class="row g-2 mt-2">
          <div class="col-4">
            <div>
              <label class="form-label">Nome</label>
              <input type="text" class="form-control form-control-sm" v-model="filters.name">
            </div>
          </div>
        </div>
      </form>
    </filters>

    <div class="card bg-white mt-4">
      <div class="card-body p-4">
        <div class="d-flex align-items-center justify-content-end">
          <span class="small text-secondary" v-if="collection.data.length">
            Mostrando {{ collection.data.length }} de {{ collection.meta.total }} registros
          </span>
        </div>
        <div class="table-responsive mt-3">

          <table class="table table-striped" v-if="collection.data.length">
            <thead>
            <tr>
              <th class="text-center">Ordem</th>
              <th>Nome</th>
              <th>Tipo</th>
              <th class="text-center">Situação</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="category in collection.data" :key="category.id">
              <td class="text-center">{{ category.order }}</td>
              <td>{{ category.name }}</td>
              <td>{{ category.parent.name}}</td>
              <td class="text-center">
                <span class="badge bg-danger" v-if="category.status === 0">Inativo</span>
                <span class="badge bg-success" v-else>Ativo</span>
              </td>
              <th>
                <div class="d-flex align-items-center justify-content-end">
                  <div class="btn-group">
                    <button type="button" class="btn btn-primary btn-sm dropdown-toggle"
                            data-bs-popper-config='{"strategy":"fixed"}' data-bs-toggle="dropdown">
                      Ações
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#" @click.prevent="edit(category.id)">Editar</a></li>
                      <li><a class="dropdown-item" href="#" @click.prevent="remove(category.id)">Remover</a></li>
                      <li v-if="category.status"><a class="dropdown-item text-danger" href="#"  @click.prevent="toggleStatus(category)">Inativar</a></li>
                      <li v-if="!category.status"><a class="dropdown-item text-success" href="#" @click.prevent="toggleStatus(category)">Ativar</a></li>
                    </ul>
                  </div>
                </div>
              </th>
            </tr>
            </tbody>
          </table>

          <div class="bg-light p-2 rounded-2 text-center" v-else>Nenhum registro encontrado...</div>

          <div class="d-flex align-items-center justify-content-center mt-4">
            <paginator
                :current-page="collection.meta.current_page"
                :limit-pages="5"
                :pages="collection.meta.last_page"
                @page-changed="handleSearch(filters, $event)"
                v-if="collection.data.length"
            />
          </div>
        </div>
      </div>
    </div>

    <modal id="category-modal" :custom-classes="['modal-dialog-centered', 'modal-md']" title="Categoria">
      <template #body>
        <div class="row g-2">
          <div class="col-9">
            <label class="form-label">Tipo</label>
            <select
                class="form-select"
                v-model="$v.payload.parent_id.$model"
                :class="{ 'is-invalid' : $v.payload.parent_id.$error }"
            >
              <option v-for="category in root" :value="category.id">{{category.name}}</option>
            </select>
            <div class="invalid-feedback" v-if="$v.payload.parent_id.$error">Campo obrigatório</div>
          </div>

          <div class="col-3">
            <label class="form-label">Ordem</label>
            <input
                type="number"
                step="1"
                min="0"
                class="form-control"
                v-model="$v.payload.order.$model"
                :class="{ 'is-invalid' : $v.payload.order.$error }"
            />
          </div>


          <div class="col-12">
            <label class="form-label">Nome</label>
            <input
                class="form-control"
                v-model="$v.payload.name.$model"
                :class="{ 'is-invalid' : $v.payload.name.$error }"
            />
            <div class="invalid-feedback" v-if="$v.payload.name.$error">Campo obrigatório</div>
          </div>

        </div>
      </template>

      <template #footer>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" ref="closeModal">Cancelar</button>
        <button type="button" class="btn btn-primary" @click.prevent="handleSave">Salvar</button>
      </template>
    </modal>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import PageHeader from "@/components/PageHeader.vue";
import Paginator from "@/components/shared/paginator.vue";
import Loader from "@/components/shared/loader.vue";
import Filters from "@/components/shared/Filters.vue";
import axios from "axios";
import Modal from "@/components/shared/Modal.vue";
import { Modal as bsModal } from "bootstrap";
import { required } from "vuelidate/lib/validators";

export default {
  name: 'lessons-categories',
  props: {},
  components: {
    Modal,
    Filters,
    Loader,
    Paginator,
    PageHeader
  },
  data() {
    return {
      loading: false,
      filters: {
        name: null,
      },
      modal: null,
      payload: {
        id: null,
        parent_id: null,
        name: null,
        order: null
      }
    }
  },
  validations() {
    return {
      payload: {
        parent_id: {required},
        name: {required,},
        order: {required}
      }
    }
  },
  async mounted() {
    this.loading = true;
    await this.getRoot();
    await this.handleSearch(this.filters, this.$route.query.page ?? 1);
    this.modal = bsModal.getOrCreateInstance(document.getElementById('category-modal'));
  },
  methods: {
    ...mapActions('LessonsCategories', ['getAll', 'getRoot', 'save', 'update']),
    clearFields() {
        this.payload = {
          id: null,
          parent_id: null,
          name: null,
          order: null
        }
        this.$v.$reset();
    },
    async handleSearch(filters, page = 1) {
      this.loading = true;
      const params = {
        page: page,
        ...filters
      }

      await this.getAll(params).finally(() => {
        this.loading = false;
        this.$scrollTop();
      })

      this.$urlUtils.assignQueryString(this.$route, params);
    },
    async handleSave() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return false;
      }

      this.loading = true;
      if(!this.payload.id) {
        await this.save(this.payload).then(() => {
          this.handleSearch({}, 1);
          this.$refs.closeModal.click();
        }).finally(() => {
          this.loading = false
        });
      } else {
        const formData = this.$serialize(this.payload);
        formData.append('_method', 'PUT');
        await axios.post(`/admin/lessons-categories/${this.payload.id}`, formData).then(() => {
          this.handleSearch({}, 1);
          this.$refs.closeModal.click();
        }).finally(() => {
          this.loading = false
        });
      }
    },
    async edit(id) {
      this.loading = true;
      await axios.get(`/admin/lessons-categories/${id}`).then(response => {
        const { data } = response;
        this.payload.id = data.id;
        this.payload.parent_id = data.parent_id;
        this.payload.name = data.name;
        this.payload.order = data.order;

        this.modal.show();

      }).finally(() => {
        this.loading = false;
      })
    },
    async remove(id) {
      await this.$swal.fire({
        text: 'Confirma a exclusão do registro selecionado?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: `Não`,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          await axios.delete(`/admin/lessons-categories/${id}`).then(() => {
            this.handleSearch({}, 1);
          }).catch(() => {
            this.$swal({
              icon: 'error',
              text: 'Essa categoria já está vinculada a algum treinamento e não pode ser excluida!'
            })
          }).finally(() => {
            this.loading = false;
          })
        }
      });
    },
    clearFilters() {
      this.filters = {
        name: null
      }

      this.handleSearch(this.filters, 1);
    },
    async toggleStatus(portal) {
      let text = 'Confirma a inativação da categoria?'
      if(portal.status === 0) {
        text = 'Confirma a ativação da categoria?'
      }
      await this.$swal.fire({
        text: text,
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: `Não`,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          await axios.post('/admin/lessons-categories/toggle-status', {
            category_id: portal.id,
          }).finally(() => {
            this.handleSearch(this.filters, 1);
            this.loading = false;
          })
        }
      });
    },
  },
  computed: {
    ...mapState('LessonsCategories', ['collection', 'root'])
  }
}
</script>
<style lang="scss" scoped>

</style>
